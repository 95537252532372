function _slicedToArray(arr,i){return _arrayWithHoles(arr)||_iterableToArrayLimit(arr,i)||_unsupportedIterableToArray(arr,i)||_nonIterableRest();}function _nonIterableRest(){throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");}function _unsupportedIterableToArray(o,minLen){if(!o)return;if(typeof o==="string")return _arrayLikeToArray(o,minLen);var n=Object.prototype.toString.call(o).slice(8,-1);if(n==="Object"&&o.constructor)n=o.constructor.name;if(n==="Map"||n==="Set")return Array.from(o);if(n==="Arguments"||/^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))return _arrayLikeToArray(o,minLen);}function _arrayLikeToArray(arr,len){if(len==null||len>arr.length)len=arr.length;for(var i=0,arr2=new Array(len);i<len;i++){arr2[i]=arr[i];}return arr2;}function _iterableToArrayLimit(r,l){var t=null==r?null:"undefined"!=typeof Symbol&&r[Symbol.iterator]||r["@@iterator"];if(null!=t){var e,n,i,u,a=[],f=!0,o=!1;try{if(i=(t=t.call(r)).next,0===l){if(Object(t)!==t)return;f=!1;}else for(;!(f=(e=i.call(t)).done)&&(a.push(e.value),a.length!==l);f=!0){;}}catch(r){o=!0,n=r;}finally{try{if(!f&&null!=t["return"]&&(u=t["return"](),Object(u)!==u))return;}finally{if(o)throw n;}}return a;}}function _arrayWithHoles(arr){if(Array.isArray(arr))return arr;}import{useState}from"react";// We can extend this for other types as needed
export var useLocalStorage=function useLocalStorage(key,initialValue){// State to store our value
// Pass initial state function to useState so logic is only executed once
// Get from local storage by key
var item=window.localStorage.getItem(key);var _useState=useState(function(){try{// Parse stored JSON or if none, return initialValue
return item?JSON.parse(item):initialValue;}catch(error){// If error occurs during parsing, return initialValue
console.error(error);return initialValue;}}),_useState2=_slicedToArray(_useState,2),storedValue=_useState2[0],setStoredValue=_useState2[1];if(!item){window.localStorage.setItem(key,JSON.stringify(initialValue));}// Return a wrapped version of useState's setter function that persists the new value to localStorage.
var setValue=function setValue(value){try{// Allow value to be a function so we have the same API as useState
var valueToStore=value instanceof Function?value(storedValue):value;// Save state
setStoredValue(valueToStore);// Save to local storage
window.localStorage.setItem(key,JSON.stringify(valueToStore));}catch(error){// A more advanced implementation would handle the error case
console.error(error);}};return[storedValue,setValue];};